import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import Layout from '../components/Layout.js';
import { Auth, ThemeSupa } from '@supabase/auth-ui-react'
import { useSession, useSupabaseClient } from '@supabase/auth-helpers-react'
import { createServerSupabaseClient } from '@supabase/auth-helpers-nextjs'

export async function getServerSideProps(context) {
    const supabase = createServerSupabaseClient(context)
    let {
        data: { session },
    } = await supabase.auth.getSession()

    return {
        props: {
            initialSession: session,
        },
    }
}

export default function Home({ }) {
    const session = useSession()
    const supabase = useSupabaseClient()

    const router = useRouter();

    useEffect(() => {
        if (session) {
            router.push('/planner');
        }
    }, [session]);

    return (
        <Layout JournalEntries home="true" session={session}>
            {/* <div className="" style={{ padding: '50px 0 100px 0' }}>
                {!session && (
                    <Auth supabaseClient={supabase} appearance={{ theme: ThemeSupa }} />
                )}
            </div> */}
            <div>
                <p>home</p>
            </div>
        </Layout >
    );
}
