import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useSupabaseClient } from "@supabase/auth-helpers-react"


export default function LogoutButton() {
    const router = useRouter();
    const supabaseClient = useSupabaseClient()

    const handleLogout = async () => {
        const { error } = await supabaseClient.auth.signOut();
        if (error) console.error('Error logging out:', error.message);
        console.log('User logged out successfully');
    };

    useEffect(() => {
        const { data: authListener } = supabaseClient.auth.onAuthStateChange(
            (event, session) => {
                if (event === 'SIGNED_OUT') {
                    router.push('/');
                }
            }
        );
    }, []);

    return (
        <button
            onClick={handleLogout}
            className="px-4 py-2 rounded hover:bg-red-600 transition duration-200"
        >
            Logout
        </button>
    );
}